/*
* Theme: EyePop
* Author: Sazzad Hossain
* Author URL: http://sazzad.me
*/

/** Fonts **/
//

/** Assets **/
@import "asset/variables";
@import "asset/mixins";
@import "base";
@import "classhelper";

/** Main **/
body {
    background: $bodyColorDark;
}

main {
    padding-top: 0;
}

.logo {
    display: inline-block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    line-height: $logoSize;
    color: white;
    font-size: $fontSizeSmall;
    @include opacity(.5);

    img {
        max-width: $logoSize;
        display: inline-block;
    }

    &:hover {
        color: white;
        @include opacity(1);
    }
}

/** Weather Card **/
#weather-box {
    position: relative;
	width: 100%;
	height: 100vh;
	background: #27ae60;

    .left {
        height: 100vh;
        text-shadow: 0 1px 0 rgba(0,0,0,0.5);
        color: white;
        @include box-shadow(inset $boxShadowHover);

        .location {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 30%;
            font-size: 25px;
            padding: $padding-xl;
            text-align: center;

            #location, #time, #summary {
                width: 100%;
            }

            #location {
            	font-size: 30px;
            }
        }

        .temperature {
            height: 40%;
            font-size: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: $padding $padding-xl;
            text-align: center;
            color: white;

            #temperature-holder {
            	margin-left: 1em;
            	display: inline-block;


                #temperature {
                	display: inline-block;
                	font-size: 4.5*$fontHeaderSize;
                    line-height: 1;
                }

                #temperature-format {
                	padding-top: $padding;
                	vertical-align: top;
                	font-size: $fontSizeLarge;
                	display: inline-block;
                }

                #changeFormat {
                	text-decoration: none;
                    color: white;
                    font-size: $fontHeaderSize;
                }
            }
        }

        #additional {
            height: 30%;
        	display: flex;
        	flex-direction: column;
            justify-content: center;

            div {
                text-align: center;
            }
        }
    }

    .bottom {
        display: block;
        height: 100vh;
        padding: 0;
        background: $bodyColorDark;

        .wrapper2 {
        	display: flex;
        	align-items: flex-end;
        	position: absolute;
        	bottom: 0;
        	width: 100%;
        	height: 100%;
        }
    }
}

.future {
    position: relative;
	width: calc(100% / 7);
	height: 100%;
	display: flex;
    margin: 0 0;
	align-items: center;
	flex-direction: column;
	justify-content: space-around;
    border-left: 1px solid $borderColor;
    @include transition(.2s all ease-in);

    &:hover {
        background: white;
        z-index: 5;
        @include box-shadow($boxShadowHover);
        @include transition(.2s all ease-in);
    }

    .day {
    	text-align: center;
    }

    .icon {
    	font-size: 50px;
    }

    .min-max-holder {
    	display: flex;
    	flex-direction: column;
    	justify-content: center;
    	align-items: center;

        .max {
            color: #111;
        }
    }
}


/** Media Queries **/

@media (max-width: ($grid-lg - 1px)) {
    .row {
        margin: 0;
    }

    #weather-box {
        height: auto;
        min-height: 100%;

        .left {
            width: 100%;
            padding: 0;
            z-index: 10;
            @include box-shadow($boxShadowHover);

            #icon0 {
                width: 64px;
                height: 64px;
            }

            .location {
                #location {
                    font-size: $fontSizeLarge;
                }

                #time {
                    font-size: $fontSizeSmall;
                    line-height: 1.2;
                }
            }

            .temperature {
                #temperature-holder {
                    #temperature {
                        font-size: 3*$fontHeaderSize;
                    }

                    #temperature-format {
                        padding: 0;
                    }
                }
            }

            #additional {
                font-size: $fontSizeSmall;

                .pt-2, .pb-2 {
                    padding: 0 !important;
                }
            }
        }

        .bottom {
            width: 100%;
            height: auto;

            .wrapper2 {
                display: block;
                width: 100%;
                min-height: 100vh;
                height: auto;
                position: relative;

                .future {
                    width: 100%;
                    height: auto;
                    padding: $padding-xl $padding;
                    display: block;
                    text-align: center;
                    border-left: none;
                    border-top: 1px solid $borderColor;
                    @include box-shadow($boxShadowHover);

                    > canvas {
                		width: 48px;
                		height: 48px;
                	}

                    .min-max-holder {
                        display: block;

                        .max, .min {
                            display: inline-block;
                            padding: 0 $padding-xs;
                        }
                        .max {
                            font-weight: bold;
                        }

                        .min {
                            &:before {
                                content: "(";
                            }

                            &:after {
                                content: "min )";
                            }
                        }
                    }
                }
            }
        }
    }
}
