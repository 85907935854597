.var {}

// Fonts
$fontSizexSmall:  15px;
$fontSizeSmall:   18px;
$fontSize:        20px;
$fontSizeLarge:   22px;
$fontSizexLarge:  30px;
$fontHeaderSize:  45px;
$fontPrimary:     "glacial indifference", system-ui, -apple-system, system-ui, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
$fontHeader:      'Comfortaa', cursive;;
$fontLight:       lighter;
$fontNormal:      normal;
$fontMedium:      500;
$fontBold:        bold;
$fontStyle:       normal;
$letterSpacing:   0;
$lineHeight:      1.77;

// Sizes
$sidebarSize:     265px;
$navSize:         100px;
$navSizeMobile:   64px;
$logoSize:        36px;
$logoSizeMobile:  36px;
$padding-xs:      5px;
$padding-sm:      2*$padding-xs;
$padding:         3*$padding-xs;
$padding-md:      4*$padding-xs;
$padding-lg:      5*$padding-xs;
$padding-xl:      6*$padding-xs;

// Bootstrap Grid Breakpoints
$grid-xs:         0;
$grid-sm:         544px;
$grid-md:         768px;
$grid-lg:         992px;
$grid-xl:         1200px;
$grid-xxl:        1400px;

$inputSizeXl:     80px;
$inputSizeLg:     70px;
$inputSize:       60px;
$inputSizeMd:     50px;
$inputSizeSm:     40px;
$inputSizeXs:     35px;
$inputSizeXxs:    14px;

// Brand Colors
$primaryColor:    #FF34B4;
$secondaryColor:  #44D0CD;
$tirtiaryColor:   #FFCC00;

// Colors
$blue:            #2962FF;
$red:             #d93025;
$yellow:          #f1c40f;
$green:           #2ecc71;
$orange:          #d35400;

$textColor:       #3C3C3C;
$textColorLight:  #6E7180;
$headerColor:     #252525;
$textColorDark:   #252525;

$white:           #FFFFFF;
$bodyColor:       #FFFFFF;
$bodyColorDark:   #F8FAFD;
$bodyColorPink:   lighten($primaryColor, 38%);
$bodyColorLight:  #f7f7f7;
$borderColor:     rgba(151,151,151,0.20);
$borderColorLight:rgba(0,0,0,0.065);
$borderColorDark: rgba(0,0,0,0.25);
$frameColor:      #E5F5FE;
$overlayColor:    rgba(255,255,255,0.5);

// Forms Colors
$greenAlert:      #6FC080;
$redAlert:        #DD6777;
$blueAlert:       #6EC7E6;
$yellowAlert:     #FFCE44;
$ltorange:        lighten($secondaryColor, 20%);
$ltgreen:         #3bffb2;
$pinkAlert:       #FAC0C0;

$boxShadow:       0 2px 5px 0 rgba(62,57,107,.1);
$boxShadowLg:     0 10px 45px 0 rgba(7,61,125,0.10);
$boxShadowSolid:  4px 4px 0px rgba(34, 45, 125, 0.1);
$boxShadowHover:  0 14px 24px rgba(62,57,107,.1);

// Social Media Brand Colors
$twitter:         #00aced;
$facebook:        #3b5998;
$googleplus:      #dd4b39;
$pinterest:       #cb2027;
$linkedin:        #007bb6;
$youtube:         #bb0000;
$vimeo:           #aad450;
$tumblr:          #32506d;
$instagram:       #517fa4;
$flickr:          #ff0084;
$dribbble:        #ea4c89;
$soundcloud:      #ff3a00;
$behance:         #1769ff;

// Animation Speed
$animateFast: 0.2s;
$animateNormal: 0.3s;
$animateSlow: 0.6s;
$t: 0.3s;
